import upperFirst from 'lodash/upperFirst';

import { transformVendorTier } from '@utils/transformVendorTier';
import NavigationHelper from '../../../helpers/navigation';

export default class VendorAnalytics {
	constructor(vendor = {}) {
		this.vendor = vendor;
	}

	sanitizedVendorUrl() {
		const LEADING_HTTP_REGEX = /^http(s)*:\/\//i;
		const url = this.vendor.websiteUrl
			? this.vendor.websiteUrl
			: this.vendor.displayWebsiteUrl;

		return LEADING_HTTP_REGEX.test(url) ? url : `http://${url}`;
	}

	sanitizedVendorBestOfWeddings() {
		const { awards } = this.vendor;

		if (awards && awards.length) {
			const removeBOWFromValues = awards.map((award) => award.split('BOW')[1]);
			const uniqArray = new Set(removeBOWFromValues);
			return Array.from(uniqArray).join(', ');
		}

		return null;
	}

	sanitizedVendorAddress() {
		const { address } = this.vendor;
		if (address) {
			return address.address;
		}
		return null;
	}

	/*
	 * Covert settings hash to flat-namespace properties structure for friendly mixpanel
	 *
	 * from:
	 * settings: {
	 *    pricing: true
	 * }
	 *
	 * to:
	 * {
	 *    vendorStringPricing: 'true'
	 * }
	 */
	flattenSettings() {
		const flattenSettings = {};
		const { settings } = this.vendor;

		if (settings == null) {
			return {};
		}
		const settingsKeys = Object.keys(settings);

		if (settingsKeys.length) {
			settingsKeys.forEach((key) => {
				const titleCase = upperFirst(key);
				flattenSettings[`vendorSettings${titleCase}`] = String(settings[key]);
			});
		}

		return flattenSettings;
	}

	buildEventProps() {
		return {
			mediaSummaryCount: String(this.vendor.mediaSummary.total),
			partnerAccountId: this.vendor.accountId,
			partnerAccountStatus: this.vendor.accountStatus,
			partnerVendorId: this.vendor.vendorId,
			referrer: NavigationHelper.referrer(),
			storefrontVersion: '3.0',
			vendorAddress: this.sanitizedVendorAddress(),
			vendorBestOfWeddings: this.sanitizedVendorBestOfWeddings(),
			vendorCategoryCode: this.vendor.categoryCode,
			vendorCity: this.vendor.address.city,
			vendorClaimedStatus: this.vendor.claimedStatus,
			vendorDisplayId: String(this.vendor.displayId),
			vendorId: this.vendor.id,
			vendorMarketCode: this.vendor.marketCode,
			vendorName: this.vendor.name,
			vendorPriceTier: this.vendor.priceRange,
			vendorPurchaseStatus: this.vendor.purchaseStatus,
			vendorReferredMarketCode:
				NavigationHelper.sanitizedVendorReferredMarketCode(
					this.vendor.marketCode,
				),
			vendorReviewCount: String(this.vendor.reviewsCount),
			vendorState: this.vendor.address.state,
			vendorUrl: this.sanitizedVendorUrl(),
			vendorVendorTier: transformVendorTier(this.vendor.vendorTier),
			vendorZip: this.vendor.address.postalCode,
			...this.flattenSettings(),
		};
	}

	/*
	 * Segment strips out properties with values undefined.
	 * For instance: not every storefront has BestOfWeddings or ReferredMarketCode.
	 *
	 * Set null to all vendor properties when they are undefined, so it provides predictable and consistent properties values.
	 */
	baseEventProps() {
		const eventProps = this.buildEventProps();

		for (const prop in eventProps) {
			if (eventProps[prop] === undefined || eventProps[prop] === '') {
				eventProps[prop] = null;
			}
		}

		return Object.freeze(eventProps);
	}
}
