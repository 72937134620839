import { useTouchScreen } from '@hooks/use-touch-screen';
import noop from '@utils/noop';
import {
	Tooltip,
	TooltipContainer,
	TooltipProps,
} from '@xo-union/tk-component-tooltips';
import { useOutsideClickEffect } from '@xo-union/ui-accessibility';
import classNames from 'classnames';
import React, { ReactNode, useCallback, useState } from 'react';
import Styles from './tooltip-wrapper.scss';

interface TooltipWrapperProps extends TooltipProps {
	children: ReactNode;
	target: ReactNode;
	containerClassName?: string;
	targetClassName?: string;
	onToggle?: (open: boolean) => void;
}

export const TooltipWrapper = ({
	children,
	target,
	containerClassName = '',
	targetClassName = '',
	className = '',
	onToggle = noop,
	...tooltipProps
}: TooltipWrapperProps) => {
	const isTouchscreen = useTouchScreen();
	const [open, setOpen] = useState(false);
	const ref = React.createRef<HTMLDivElement>();
	const toggleTooltip = useCallback(() => {
		if (isTouchscreen) {
			onToggle(!open);
			setOpen((value) => {
				return !value;
			});
		}
	}, [isTouchscreen, open]);
	useOutsideClickEffect({ onClickOutside: () => setOpen(false) }, ref);

	return (
		<TooltipContainer
			data-testid="tooltip-container"
			className={classNames(containerClassName, Styles.container, {
				[Styles.noHeader]: !tooltipProps.header,
			})}
		>
			<div
				ref={ref}
				className={classNames(targetClassName, Styles.target)}
				role="button"
				tabIndex={0}
				onClick={toggleTooltip}
				onKeyDown={toggleTooltip}
			>
				{target}
			</div>
			<Tooltip
				{...tooltipProps}
				className={classNames(className, Styles.tooltip, {
					[Styles.closed]: !open,
				})}
			>
				{children}
			</Tooltip>
		</TooltipContainer>
	);
};
