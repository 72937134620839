const fragment = `
fragment FacetFieldsFull on SubFacet {
  id
  name
  description
  singular {
    slug
    term
  }
  plural {
    slug
    term
  }
  isSelected
}
`;

export default fragment;
