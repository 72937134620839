import affiliates from '../../api/graphql/Storefront/affiliates';
import facets from '../../api/graphql/Storefront/facets/full';

const fragment = `
  fragment ProfileProperties on Storefront {
    id
    vendorId
    accountId
    accountStatus
    name
    displayId
    description
    ivolScoreId
    headline
    websiteUrl
    displayWebsiteUrl
    purchaseStatus
    claimedStatus
    adTier
    salesProfiles {
      vendorTier
      marketCode
      addOns {
        code
        name
      }
    }
    addOns {
      code
    }
    vendorTier
    movedProfileId
    location {
      address {
        address1
        address2
        city
        state
        postalCode
        latitude
        longitude
        isPublic
      }
      serviceArea
    }
    emails {
      address
      type
    }
    mediaSummary {
      total
    }
    statistics {
      ivolScore
    }
    storefrontCard {
      height
      id
      mediaType
      url
      width
    }
    vendorBehavior {
      quickResponder
    }
    logo {
      url
    }
    reviewSummary {
      count
      overallRating
    }
    siteUrls {
      siteId
      siteName
      uri
      relativeUri
    }
    categories {
      code
      name
      displayName
    }
    marketCode
    settings {
      pricing
      enhancedRFQ
    }
    ai {
      photo {
        height
        id
        mediaType
        url
        width
      }
    }
    facets {
      id
      name
      ...FacetsRecurseFull
    }
    affiliates {
      id
      name
      affiliates {
        id
        name
      }
      description
      singular {
        slug
        term
      }
      plural {
        slug
        term
      }
      isSelected
      ...AffiliatesRecurse
    }
    categoryInfo {
      plural {
        slug
        term
      }
   }
}

${affiliates}
${facets}
`;

export default fragment;
