import * as React from 'react';

const PictureThumbnail = () => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
		<title>Picture Thumbnail</title>
		<path fill="#E8D5CA" d="m4.255 7.754 1.578 4.734h4.136l.816-4.734h-6.53Z" />
		<path
			fill="#F7E6DC"
			d="M22.69 6.767a.197.197 0 0 1 .182-.162l16.81-1.064a.197.197 0 0 1 .202.143l2.182 7.623a.197.197 0 0 1-.19.251H21.693a.197.197 0 0 1-.194-.232l1.192-6.559Z"
		/>
		<path
			fill="#F7E6DC"
			d="M0 12.833c0-.872.707-1.578 1.578-1.578h44.207c.854 0 1.553.68 1.577 1.534L48 40.516a1.578 1.578 0 0 1-1.551 1.622l-44.844-.797A1.578 1.578 0 0 1 0 39.763v-26.93Z"
		/>
		<path
			fill="#E8D5CA"
			d="M22.987 14.979c6.835-1.406 13.207 2.512 14.224 8.785 1.048 6.257-3.68 12.448-10.512 13.855-6.837 1.405-13.208-2.51-14.224-8.785-1.004-6.242 3.705-12.466 10.512-13.855Z"
		/>
		<path
			fill="#E8D5CA"
			d="M24.492 19.475c4.14-.383 7.656 2.356 7.846 6.136.21 3.773-2.997 7.13-7.135 7.514-4.14.383-7.656-2.355-7.846-6.135-.184-3.763 3.014-7.138 7.135-7.515Z"
		/>
		<path
			fill="#FFF5ED"
			d="M24.492 19.475c4.14-.383 7.656 2.356 7.846 6.136.21 3.773-2.997 7.13-7.135 7.514-4.14.383-7.656-2.355-7.846-6.135-.184-3.763 3.014-7.138 7.135-7.515Z"
		/>
		<path
			fill="#E8D5CA"
			d="M26.305 8.451s6.62-1.434 7.368-7.288c0 0 4.021 5.218 9.795 4.395 0 0-3.367 4.056-.8 10.794 0 0-6-2.76-10.947 2.885.016.02-.223-6.764-5.413-10.787l-.003.001Z"
		/>
	</svg>
);
export default PictureThumbnail;
