import { useIsVenue } from '@hooks/use-is-venue';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { startingCostAtom } from '../../../jotai/storefront/pricing';

export const useVenuePricingRfqSubheaderText = () => {
	const isVenue = useIsVenue();
	const startingCost = useAtomValue(startingCostAtom);

	return useMemo(() => {
		if (!isVenue || !startingCost) {
			return null;
		}

		return `${startingCost} starting price`;
	}, [isVenue, startingCost]);
};
