import * as guards from '@api/helpers/guards';
import { Logger } from '@utils/logger/logger';

const getMessage = (name: API.ErrorName): string => {
	if (guards.isGraphQLError(name)) {
		return `GraphQL query ${name} returned with an error`;
	}

	if (guards.isSuperagentError(name)) {
		return `Superagent response within ${name} was not a 200`;
	}

	return name;
};

const report = <U>(name: API.ErrorName, error: Error): void => {
	const message = getMessage(name);
	Logger.error({
		log_message: message,
		newRelic: true,
		errorToLog: error,
	});
};

export default report;
