import profileProperties from './profile_properties';

export default `
  query similarity(
    $id: ID!,
    $limit: Int,
    $location: LocationInput,
    $radius:Int,
    $version: String
  ) {
    similarity(
      id: $id,
      limit:$limit,
      location: $location,
      radius:$radius,
      version: $version
    ) {
      profiles {
        ...ProfileProperties
      }
      factors {
        storefrontId
        similarFacets
      }
      marketCode
    }
  }
  ${profileProperties}
`;
