export const EVENT_MODAL_VIEWED = 'Modal Viewed';
export const EVENT_CTA_CLICKED = 'CTA Clicked';

export const VRM_CONFIRMATION_MODAL_TYPE = 'VRM Confirmation';
export const VRM_CONFIRMATION_NEW_USER_MODAL_TYPE =
	'VRM Confirmation - New user';

export const TRACKING_REASON = 'VRM Confirmation Modal';

export const ACTION_DISMISS = 'dismiss';
export const ACTION_CLICK_INBOX = 'click inbox';
export const ACTION_CLICK_KEEP_GOING = 'click keep going';
export const ACTION_CLICK_VENDOR = 'click vendor';
