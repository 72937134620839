import loadable from '@loadable/component';
import type { DatePickerDropdown as DatePickerDropdownType } from '@xo-union/tk-component-datepicker';
import { Bone, Skeleton } from '@xo-union/tk-ui-skeleton';
import React, { useEffect, useState } from 'react';

const DatePickerDropdown = loadable(
	async () =>
		import(
			/* webpackChunkName: "tkComponentDatepicker" */ /* webpackPreload: true */ '@xo-union/tk-component-datepicker'
		),
	{
		resolveComponent: (components) => components.DatePickerDropdown,
	},
);

type Props = Parameters<typeof DatePickerDropdownType>[0] & {
	skeletonStyle?: React.CSSProperties;
};

export function DatePickerDropdownWrapper({ skeletonStyle, ...props }: Props) {
	const [render, setRender] = useState(false);

	useEffect(() => {
		setRender(true);
	}, []);

	if (!render) {
		return (
			<Skeleton aria-label="Loading date picker">
				<Bone style={{ height: 48, width: '100%', ...skeletonStyle }} />
			</Skeleton>
		);
	}

	return <DatePickerDropdown {...props} />;
}
