import NavigableModal, {
	type NavigableModalProps,
} from '@components/NavigableModal/NavigableModal';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Body1, Editorial, Subhead } from '@xo-union/tk-ui-typography';
import React, { useEffect, type FC } from 'react';
import AnalyticsEvents from '../../../../constants/analytics';
import { ModalBackground } from '../components/ModalBackground';
import PasswordDialog from '../components/PasswordDialog/PasswordDialog';
import TheKnotSmallLogo from '../components/TheKnotSmallLogo/TheKnotSmallLogo';
import type { Analytics, TrackConfModalViewedProperties } from '../types';
import {
	ACTION_DISMISS,
	TRACKING_REASON,
	VRM_CONFIRMATION_NEW_USER_MODAL_TYPE,
} from '../utils/constants';
import {
	trackConfirmationModalCtaClick,
	trackConfirmationModalViewed,
} from '../utils/tracking';
import Styles from './ConfirmationAndPasswordModal.scss';
import { ContactedVendors } from './components/ContactedVendorsCarousel';
import { RoundVendorCard } from './components/RoundVendorCard';

const { SAVE_PASSWORD } = AnalyticsEvents;

export interface ConfirmationAndPasswordModalProps
	extends Omit<NavigableModalProps, 'hash'> {
	closeCallback: () => void;
	contactedVendors: API.FESharedProfile[];
	indirectLeadsCount: number;
	changePasswordStatus: Redux.Rfq['changePasswordStatus'];
	handleSubmit: (analytics: Analytics) => void;
	password: string;
	updatePassword: (password: string) => void;
	sourcePage: string;
}

export const VRM_CONFIRMATION_ANIMATION_DURATION = 1300;

const ConfirmationAndPasswordModal: FC<ConfirmationAndPasswordModalProps> = ({
	contactedVendors,
	indirectLeadsCount,
	sourcePage,
	changePasswordStatus,
	password,
	handleSubmit,
	updatePassword,
	...props
}) => {
	const { track } = useAnalyticsContext();

	useEffect(() => {
		trackConfirmationModalViewed(track, {
			contacted_vendor_count: indirectLeadsCount,
			modal_type: VRM_CONFIRMATION_NEW_USER_MODAL_TYPE,
		});
	}, [indirectLeadsCount, track]);

	const setCtaTrackProperties = (
		action: string,
		selection: string,
		sourceContent: string,
	): TrackConfModalViewedProperties => {
		return {
			source_page: sourcePage,
			action,
			selection,
			sourceContent,
			reason: TRACKING_REASON,
		};
	};

	const handleModalClose = () => {
		props.closeCallback();
		trackConfirmationModalCtaClick(
			track,
			setCtaTrackProperties(
				ACTION_DISMISS,
				'close',
				VRM_CONFIRMATION_NEW_USER_MODAL_TYPE,
			),
		);
	};

	const isVenueVendor = contactedVendors[0].categories.some(
		(category) => category.code === 'REC',
	);

	const isSingleVendor = contactedVendors.length === 1;

	return (
		<NavigableModal
			hash="confirmation-password-modal"
			isModalOpen={props.isModalOpen}
			closeCallback={handleModalClose}
			onOverlayClick={handleModalClose}
			className={Styles.modal}
		>
			<ModalBackground />
			<div className={Styles.headerContainer}>
				<Subhead bold>
					{indirectLeadsCount ? 'You’re on a roll' : 'Nice job'}
				</Subhead>
				<Body1>
					{indirectLeadsCount
						? `You messaged ${indirectLeadsCount} more ${
								isVenueVendor ? 'venue' : 'vendor'
							}${indirectLeadsCount === 1 ? '' : 's'}. Nice job.`
						: 'Your message was sent to:'}
				</Body1>
			</div>

			{isSingleVendor ? (
				<div className={Styles.singleVendorContainer}>
					<RoundVendorCard
						vendor={contactedVendors[0]}
						sourcePage={sourcePage}
						isSingleVendor={isSingleVendor}
					/>
				</div>
			) : (
				<ContactedVendors
					contactedVendors={contactedVendors}
					sourcePage={sourcePage}
				/>
			)}
			<div className={Styles.passwordContainer}>
				<div className={Styles.passwordTextWrapper}>
					<div className={Styles.smallLogo}>
						<TheKnotSmallLogo />
					</div>
					<div className={Styles.passwordTextContainer}>
						<Editorial bold>Set a password to track vendor replies</Editorial>
						<Body1 className={Styles.leadsColor}>
							This gives you access to your vendor inbox on The Knot
						</Body1>
					</div>
				</div>

				<div className={Styles.passwordDialog}>
					<PasswordDialog
						closeCallback={handleModalClose}
						changePasswordStatus={changePasswordStatus}
						handleSubmit={handleSubmit}
						updatePassword={updatePassword}
						submitAnalytics={{
							action: SAVE_PASSWORD,
							sourceContent: VRM_CONFIRMATION_NEW_USER_MODAL_TYPE,
						}}
						password={password}
					/>
				</div>
			</div>
		</NavigableModal>
	);
};

export default ConfirmationAndPasswordModal;
