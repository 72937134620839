import { PERSONALIZED_QUOTE_HEADER_TEXT } from '@components/detailed-pricing/PricingCta';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useAppSelector } from '@redux/hooks';
import { useStorefrontFeatureScannability } from '../../../pages/Storefront/hooks/useStorefrontFeatureScannability';

export const useDetailedPricingRfqSubheaderText = (headerText?: string) => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const isInDetailedPricing = useDetailedPricing();
	const isBvcPlusScannabilityEnabled = useStorefrontFeatureScannability();

	if ((!isInDetailedPricing && !isBvcPlusScannabilityEnabled) || !vendor) {
		return null;
	}

	if (isBvcPlusScannabilityEnabled) {
		return 'Use this secure form to reach out and share your wedding vision.';
	}

	if (headerText === PERSONALIZED_QUOTE_HEADER_TEXT) {
		return `Use this secure form to reach out to ${vendor.name} and ask for details about pricing.`;
	}

	return `Use this secure form to reach out to ${vendor.name} and share your wedding vision.`;
};
