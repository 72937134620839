export default `
query(
  $anonymousId: String,
  $categoryCode: String!,
  $location: LocationInput!,
  $limit: Int,
  $apiKey: String!,
) {
  pvRecAnon(
      anonymousId: $anonymousId,
      categoryCode: $categoryCode,
      location: $location,
      limit: $limit,
      apiKey: $apiKey
  ) {
      profiles {
          ai {
            photo {
                id    
            }
          }
          id
          name
          accountId
          accountStatus
          categories {
              code
          }
          vendorId
          displayId
          marketCode
          claimedStatus
          purchaseStatus
          websiteUrl
          displayWebsiteUrl
          vendorTier
          siteUrls {
              siteId
              relativeUri
          }
          reviewSummary {
              count
              overallRating
          }
          facets {
              id
              facets {
                  name
                  facets {
                      facets {
                          id
                          name
                      }
                  }
              }
          }
          storefrontCard {
              id
              url
          }
          mediaSummary {
              total
          }
          location {
              serviceArea
              address {
                  address1
                  city
                  state
                  postalCode
                  isPublic
              }
          }
          affiliates {
              affiliates {
                  name
              }
          }
      }
  }
}
`;
