import * as React from 'react';

export const ModalBackground = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="auto"
		height="100%"
		fill="none"
		viewBox="0 0 560 680"
		preserveAspectRatio="xMidYMid slice"
	>
		<title>Modal BG</title>
		<path fill="#FFF5ED" d="M0 0h560v680H0z" />
		<g clipPath="url(#a)">
			<path
				fill="#A1CDF5"
				d="M88.898 408.942c-2.228-1.89-5.625-2.317-8.235-.99-2.61 1.305-4.343 4.253-4.163 7.155.135 2.205 1.305 4.275 3.06 5.625.338.248.72.495 1.08.698.023.022.045.022.068.045l-1.62 2.272s2.362 1.463 4.095.968l.135-2.453c1.62.203 2.407-.022 3.915-.765.967-.495 1.867-1.17 2.565-2.025a7.185 7.185 0 0 0 1.35-2.52c.945-2.835 0-6.12-2.25-8.01Z"
			/>
		</g>
		<g clipPath="url(#b)">
			<g clipPath="url(#c)">
				<path
					fill="#FDDE81"
					d="m500.118 583.113-1.693-2.048c-1.423-1.709-3.934-1.972-6.099-.784-.019-.079-1.085-1.214-2.078-.202-.917.93-.183 2.126-.118 2.155-1.445 2.034-1.593 4.651-.152 6.376l1.692 2.048c.871 1.047 1.324 2.382 1.319 3.815.012.286-.008.574-.06.862l1.056 1.279c1.514-.349 3.938-1.818 6.43-3.959 2.721-2.357 4.658-4.825 5.044-6.274l.015-.017-1.004-1.2-.05-.046c-1.739-.072-3.281-.789-4.302-2.005Z"
				/>
			</g>
		</g>
		<g clipPath="url(#d)">
			<path
				fill="#FEBBF7"
				d="M498.442 167.114c-.585-3.825 4.41-4.095 6.098-2.407l1.057-7.718c2.543-1.012 4.23-.382 6.008.27l-.203 3.443c-2.002-1.215-3.825-1.193-4.545-.63-.022.225-.337 3.015-.472 3.87-.18 1.305-.315 2.7-1.103 3.825a4.223 4.223 0 0 1-2.857 1.732c-1.598.248-3.69-.585-3.983-2.385Z"
			/>
		</g>
		<g clipPath="url(#e)">
			<path
				fill="#FF9357"
				d="m44.439 263.47-.023-.067a.824.824 0 0 0-.472-.518 1.907 1.907 0 0 0-.563-.135 6.4 6.4 0 0 0-1.102.045l-.135-.54c-.09-.315-.18-.652-.248-.99l-.855-3.172c.18-.203.495-.585.653-1.013.472-1.17.405-2.812.247-3.982a20.233 20.233 0 0 0-.922-4.073l-.158-.472-3.802.922c.09-.427.202-.922.247-1.147l.09-.518-5.67-1.372-.18.45c-.472 1.237-.9 2.947-1.147 4.477-.135.9-.225 1.71-.225 2.408 0 .832.09 1.507.27 2.025v.022c.135.36.337.698.562.968-.292 1.372-.675 2.835-1.215 4.725a5.05 5.05 0 0 0-1.17 0c-.225.022-.405.09-.562.157-.225.113-.383.27-.45.473-.113.27-.045.562.157.832.135.158.315.315.563.45.427.248 1.035.473 1.687.608.63.135 1.283.202 1.8.18a2.98 2.98 0 0 0 .72-.135c.383-.135.54-.383.585-.563a.9.9 0 0 0-.09-.72 1.43 1.43 0 0 0-.45-.45c-.247-.18-.585-.337-.99-.472.023-.113.068-.203.09-.315l.068-.27c.09-.293.157-.585.247-.9l.855-3.173c.338-.112.765-.315 1.08-.585.54-.45 1.058-1.17 1.508-2.115.135-.292.292-.607.427-.967.18.585.36 1.147.563 1.642.517 1.283 1.102 2.205 1.755 2.768.315.27.652.472 1.012.585.473 1.53.923 3.105 1.328 4.657-.405.158-.765.36-1.013.54-.18.135-.315.27-.405.405-.135.203-.202.428-.157.653.045.27.247.495.54.63.18.067.427.112.697.135h.135c.473 0 1.058-.09 1.643-.248a7.128 7.128 0 0 0 1.665-.697c.247-.158.427-.293.562-.45.248-.248.27-.495.248-.698Z"
			/>
		</g>
		<g clipPath="url(#f)">
			<path
				fill="#C4DFF9"
				d="M451.72 364.391s6.39-1.462 7.043-7.132c0 0 3.937 4.995 9.517 4.14 0 0-3.217 3.96-.652 10.44 0 0-5.828-2.588-10.553 2.902 0 .023-.292-6.525-5.355-10.35Z"
			/>
		</g>
		<g clipPath="url(#g)">
			<path
				fill="#A1CDF5"
				d="M59.739 73.379c-.067-.54-.495-.9-1.215-1.058-.495-.112-1.17-.112-1.913-.022h-.044c-.09 0-.158.022-.225.022 0-.015-.008-.03-.023-.045l-.09-.292a11.277 11.277 0 0 1-.45-2.138l-.135-1.035c.383-.18.698-.405.99-.652 1.62-1.305 2.722-3.6 1.35-5.58-.068-.113-.18-.248-.293-.405-.247-.36-.607-.855-.99-1.463-.652-1.035-1.102-1.912-1.304-2.61l-.158-.517-6.277 1.485v.45c0 1.507-.136 2.79-.383 3.622-.068.225-.135.495-.203.743v.022c-.36 1.508.068 2.993 1.193 4.073a5.205 5.205 0 0 0 3.6 1.417c.36 1.035.585 2.07.675 3.15l.045.405c-.045.023-.09.023-.135.045-.742.293-1.35.608-1.777.945-.248.18-.428.383-.563.563-.18.27-.248.517-.225.81.045.36.292.652.675.832.27.135.63.203 1.035.248.135.022.292.022.428.022.72 0 1.552-.112 2.43-.315 1.035-.247 2.002-.63 2.722-1.057.383-.225.675-.45.877-.675.316-.338.428-.675.383-.99Z"
			/>
		</g>
		<g clipPath="url(#h)">
			<path
				fill="#FEBBF7"
				d="M51.073 559.8c-3.24-1.868-8.1-.158-10.912 3.825-2.813 3.96-2.475 8.707.765 10.575 3.24 1.868 8.1.158 10.912-3.825 2.813-3.96 2.475-8.708-.765-10.575Zm-4.027 7.83c-.518.697-1.373 1.012-1.958.675-.585-.338-.63-1.193-.135-1.89.518-.72 1.373-1.013 1.958-.675.562.315.63 1.17.135 1.89Z"
			/>
		</g>
		<g clipPath="url(#i)">
			<path
				fill="#FEBBF7"
				d="M80.44 77.983c-1.091-3.41-5.294-5.12-9.41-3.814-4.091 1.306-6.532 5.14-5.44 8.55.423 1.32 2.26 2.795 2.26 2.795.075.92-1.075 2.323-1.548 2.88-.083.098-.025.251.11.24.857-.002 3.257-.198 4.685-1.783 1.043.05 2.825.04 3.924-.32 4.072-1.281 6.513-5.116 5.42-8.548Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M72 404h24v24H72z" />
			</clipPath>
			<clipPath id="b">
				<path
					fill="#fff"
					d="m477.981 587.635 16.26-17.653 17.652 16.259-16.259 17.653z"
				/>
			</clipPath>
			<clipPath id="c">
				<path
					fill="#fff"
					d="m477.981 587.635 16.26-17.653 17.652 16.259-16.259 17.653z"
				/>
			</clipPath>
			<clipPath id="d">
				<path fill="#fff" d="M493 151h24v24h-24z" />
			</clipPath>
			<clipPath id="e">
				<path fill="#fff" d="M24 244h24v24H24z" />
			</clipPath>
			<clipPath id="f">
				<path fill="#fff" d="M448 354h24v24h-24z" />
			</clipPath>
			<clipPath id="g">
				<path fill="#fff" d="M42 55h24v24H42z" />
			</clipPath>
			<clipPath id="h">
				<path fill="#fff" d="M34 555h24v24H34z" />
			</clipPath>
			<clipPath id="i">
				<path
					fill="#fff"
					d="M60.125 69.955 84.045 68l1.956 23.92-23.92 1.955z"
				/>
			</clipPath>
		</defs>
	</svg>
);
