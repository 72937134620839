import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useFeatureFlags } from '@feature-flags/use-feature-flags';
import { useAppSelector } from '@redux/hooks';

export const useStorefrontFeatureBvcPlusPricing = () => {
	const featureFlags = useFeatureFlags();
	const vendor = useAppSelector((state) => state.vendor.vendor);

	return Boolean(
		featureFlags[FEATURE_FLAGS.STOREFRONT_DETAILED_PRICING] && vendor?.isPaid,
	);
};
