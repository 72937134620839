import NavigableModal, {
	type NavigableModalProps,
} from '@components/NavigableModal/NavigableModal';
import Icon from '@xo-union/tk-component-icons';
import { Body2, Editorial } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC } from 'react';
import AnalyticsConstants from '../../../../constants/analytics';
import PasswordDialog from '../components/PasswordDialog/PasswordDialog';
import TheKnotSmallLogo from '../components/TheKnotSmallLogo/TheKnotSmallLogo';
import type { Analytics } from '../types';
import styles from './PasswordModal.scss';

const { DISMISS, PASSWORD_CREATE } = AnalyticsConstants;

export const parseEmail = (state: Redux.State) => {
	const email = state.rfq.inline.fields.emailAddress.value;

	return email || state.membership?.member?.email || '';
};

export interface PasswordModalProps extends Omit<NavigableModalProps, 'hash'> {
	changePasswordStatus: Redux.Rfq['changePasswordStatus'];
	closeCallback: (analytics: Analytics) => void;
	handleSubmit: (analytics: Analytics) => void;
	submitAnalytics: Analytics;
	password: string;
	updatePassword: (password: string) => void;
}

const PasswordModal: FC<PasswordModalProps> = (props) => {
	const {
		changePasswordStatus,
		isModalOpen,
		closeCallback,
		submitAnalytics,
		handleSubmit,
		password,
	} = props;

	const handleCloseModal = () => {
		closeCallback({ sourceContent: PASSWORD_CREATE, action: DISMISS });
	};

	return (
		<>
			<NavigableModal
				hash="password-modal"
				isModalOpen={isModalOpen}
				closeCallback={handleCloseModal}
				className={styles.modal}
				size="md"
			>
				<div className={styles.container}>
					<div className={styles.content}>
						<div className={styles.headerContainer}>
							<TheKnotSmallLogo />
							<Editorial bold className={classNames({ [styles.header]: true })}>
								Set a password to finish creating your account
							</Editorial>
						</div>
						<div className={styles.listContainer}>
							<div className={styles.listItem}>
								<Icon size="sm" name="checkmark_mini" />
								<Body2>Track vendor conversations in your inbox</Body2>
							</div>
							<div className={styles.listItem}>
								<Icon size="sm" name="checkmark_mini" />
								<Body2>Get notified as vendors reply in The Knot app</Body2>
							</div>
							<div className={styles.listItem}>
								<Icon size="sm" name="checkmark_mini" />
								<Body2>
									Create a wedding website, guest list, registry and more
								</Body2>
							</div>
						</div>
						<div>
							<PasswordDialog
								changePasswordStatus={changePasswordStatus}
								closeCallback={closeCallback}
								handleSubmit={handleSubmit}
								submitAnalytics={submitAnalytics}
								password={password}
								updatePassword={props.updatePassword}
							/>
						</div>
					</div>
				</div>
			</NavigableModal>
		</>
	);
};

export default PasswordModal;
