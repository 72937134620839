import { useShowAvailability } from '@hooks/use-show-availability';
import * as ExperimentActions from '@redux/experiments';
import { VRMLegalUpdateSelector } from '@redux/experiments/selectors/vrm-legal-update';
import { VRMLegalUpdateMobileSelector } from '@redux/experiments/selectors/vrm-legal-update-mobile';
import { getSimilarVendors as getSimilarVendorsAction } from '@redux/vrm/thunks';
import { experiments } from '@settings';
import type { State } from '@typings/redux';
import { noop } from '@utils/noop';
import { useSetAtom } from 'jotai';
import React, {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { connect } from 'react-redux';
import type { FCWithChildren } from 'types/react-extended';
import type { InlineRfqSourceContent } from 'types/sourceContent';
import type { Raw, Similar } from 'types/vendor';
import { fetchAvailabilityAtom } from '../../pages/Storefront/components/AvailabilitySection/availability-atoms';
import type { GetSimilarVendors } from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/RFQModal.d';
import {
	filterVendors,
	getVendorsRequested,
	selectUserMaxGuestCount,
} from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/utils';

export interface RecommendedContextPropsValue {
	modal: string;
	vrmOpenedFrom: string;
	onClose: () => void;
	recommendedVendors: Similar[];
	setModal: (modal: string) => void;
	openModal: (sourceContent: InlineRfqSourceContent) => void;
	initiator:
		| {
				sourceContent: InlineRfqSourceContent;
		  }
		| Record<string, unknown>;
	userMaxGuestCount?: number;
	vrmLegalUpdateAssignment?: string;
	vrmLegalUpdateMobileAssignment?: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
interface DispatchProps {
	getSimilarVendors: GetSimilarVendors;
	reportServerSideExperiment: (idExperiment: Experiments.Id) => void;
}
type RecommendedContextProps = StateProps & DispatchProps;

const RecommendedContext = createContext<RecommendedContextPropsValue>({
	modal: '',
	vrmOpenedFrom: '',
	onClose: noop,
	recommendedVendors: [],
	openModal: noop,
	setModal: noop,
	initiator: {},
	userMaxGuestCount: 0,
	vrmLegalUpdateAssignment: '',
	vrmLegalUpdateMobileAssignment: '',
});

const ContextProvider: FCWithChildren<RecommendedContextProps> = (props) => {
	const {
		children,
		getSimilarVendors,
		messagedVendors,
		pageType,
		searchLocation,
		searchPageLocation,
		similarVendors,
		vendorRaw,
		userMaxGuestCount,
		vrmLegalUpdateAssignment,
		vrmLegalUpdateMobileAssignment,
		reportServerSideExperiment,
	} = props;
	const [modal, setModal] = useState('');
	const [vrmOpenedFrom, setvrmOpenedFrom] = useState('');
	const [initiator, setInitiator] = useState<
		{ sourceContent: InlineRfqSourceContent } | Record<string, unknown>
	>({});
	const shouldShowAvailability = useShowAvailability() || false;
	const fetchAvailability = useSetAtom(fetchAvailabilityAtom);

	const recommendedVendors = useMemo(() => {
		return filterVendors(messagedVendors, similarVendors, userMaxGuestCount);
	}, [messagedVendors, similarVendors, userMaxGuestCount]);

	useEffect(() => {
		shouldShowAvailability &&
			vendorRaw &&
			fetchAvailability({ vendorId: vendorRaw.id });
	}, [shouldShowAvailability, vendorRaw, fetchAvailability]);

	useEffect(() => {
		const radius = 25;
		const screenSize = window.innerHeight;
		const vendorsRequested = getVendorsRequested(
			screenSize,
			vrmLegalUpdateAssignment,
			vrmLegalUpdateMobileAssignment,
		);

		if (vrmLegalUpdateAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('VRM Legal Update: ', vrmLegalUpdateAssignment);
			reportServerSideExperiment(experiments.VRMLegalUpdate);
		}

		if (vrmLegalUpdateMobileAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('VRM Legal Update Mobile: ', vrmLegalUpdateMobileAssignment);
			reportServerSideExperiment(experiments.VRMLegalUpdateMobile);
		}

		getSimilarVendors(
			vendorRaw,
			vendorsRequested,
			radius,
			searchLocation,
			searchPageLocation,
			pageType,
		);
	}, [
		getSimilarVendors,
		vendorRaw,
		searchLocation,
		searchPageLocation,
		pageType,
		reportServerSideExperiment,
		vrmLegalUpdateAssignment,
		vrmLegalUpdateMobileAssignment,
	]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: dependencies are correct
	const openModal = useCallback(
		(sourceContentValue: InlineRfqSourceContent) => {
			setInitiator({
				sourceContent: sourceContentValue,
			});
			setModal('vrm');
			setvrmOpenedFrom(sourceContentValue);
		},
		[setModal, setInitiator],
	);

	const onClose = useCallback(() => {
		setModal('');
		setvrmOpenedFrom('');
	}, []);

	const value = useMemo(
		() => ({
			modal,
			openModal,
			setModal,
			onClose,
			recommendedVendors,
			initiator,
			vrmOpenedFrom,
		}),
		[modal, recommendedVendors, onClose, openModal, initiator, vrmOpenedFrom],
	);

	return (
		<RecommendedContext.Provider value={value}>
			{children}
		</RecommendedContext.Provider>
	);
};

const mapStateToProps = (state: State) => ({
	messagedVendors: state.messagedVendors.conversations,
	pageType: state.page.pageType,
	searchLocation: state.location,
	searchPageLocation: state.searchPageLocation,
	similarVendors: state.vrm.similarVendors,
	vendorRaw: state.vendor.vendorRaw as Raw,
	userMaxGuestCount: selectUserMaxGuestCount(state),
	vrmLegalUpdateAssignment: VRMLegalUpdateSelector(state),
	vrmLegalUpdateMobileAssignment: VRMLegalUpdateMobileSelector(state),
});

const mapDispatchToProps = {
	getSimilarVendors: getSimilarVendorsAction,
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

const RecommendedContextProvider = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContextProvider);

export { RecommendedContextProvider };

export default RecommendedContext;
