import NavigableModal, {
	NavigableModalProps,
} from '@components/NavigableModal/NavigableModal';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Button } from '@xo-union/tk-component-buttons';
import { Body1, Editorial } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { ModalBackground } from '../components/ModalBackground';
import { TrackConfModalViewedProperties } from '../types';
import {
	ACTION_CLICK_KEEP_GOING,
	ACTION_DISMISS,
	TRACKING_REASON,
	VRM_CONFIRMATION_MODAL_TYPE,
} from '../utils/constants';
import {
	trackConfirmationModalCtaClick,
	trackConfirmationModalViewed,
} from '../utils/tracking';
import Styles from './VRMConfirmationModal.scss';
import { ContactedVendors } from './components/ContactedVendors';
import { InboxLink } from './components/InboxLink';

export interface VRMConfirmationModalProps
	extends Omit<NavigableModalProps, 'hash'> {
	closeCallback: () => void;
	contactedVendors: API.FESharedProfile[];
	indirectLeadsCount: number;
	onNextButtonClick: () => void;
	sourcePage: string;
}

export const VRM_CONFIRMATION_ANIMATION_DURATION = 1300;

const VRMConfirmationModal: FC<VRMConfirmationModalProps> = ({
	contactedVendors,
	indirectLeadsCount,
	onNextButtonClick,
	sourcePage,
	...props
}) => {
	const { track } = useAnalyticsContext();
	const [isModalClosing, setIsModalClosing] = React.useState(false);

	useEffect(() => {
		trackConfirmationModalViewed(track, {
			contacted_vendor_count: indirectLeadsCount,
			modal_type: VRM_CONFIRMATION_MODAL_TYPE,
		});
	}, [indirectLeadsCount, track]);

	const setCtaTrackProperties = (
		action: string,
		selection: string,
	): TrackConfModalViewedProperties => {
		return {
			source_page: sourcePage,
			action,
			selection,
			reason: TRACKING_REASON,
		};
	};

	const handleModalClose = () => {
		setIsModalClosing(true);
		setTimeout(() => {
			setIsModalClosing(false);
			props.closeCallback();
		}, VRM_CONFIRMATION_ANIMATION_DURATION);
		trackConfirmationModalCtaClick(
			track,
			setCtaTrackProperties(ACTION_DISMISS, 'close'),
		);
	};

	const handleCtaClick = () => {
		setIsModalClosing(true);
		setTimeout(() => {
			setIsModalClosing(false);
			onNextButtonClick();
		}, VRM_CONFIRMATION_ANIMATION_DURATION);
		trackConfirmationModalCtaClick(
			track,
			setCtaTrackProperties(ACTION_CLICK_KEEP_GOING, 'Keep going'),
		);
	};

	const isVenueVendor = contactedVendors[0]?.categories.some(
		(category) => category.code === 'REC',
	);

	return (
		<NavigableModal
			hash="vrm-confirmation-modal"
			isModalOpen={props.isModalOpen}
			closeCallback={handleModalClose}
			onOverlayClick={handleModalClose}
			className={classNames(
				Styles.modal,
				isModalClosing ? Styles.modalClosing : '',
			)}
		>
			<ModalBackground />
			<div className={Styles.itemContainer}>
				<Editorial bold>You’re on a roll</Editorial>
				<Body1>
					You messaged
					{indirectLeadsCount
						? ` ${indirectLeadsCount} more ${isVenueVendor ? 'venue' : 'vendor'}${
								indirectLeadsCount === 1 ? '' : 's'
							}. Nice job.`
						: ':'}
				</Body1>
				{!!indirectLeadsCount && (
					<InboxLink setCtaTrackProperties={setCtaTrackProperties} />
				)}
				<ContactedVendors
					contactedVendors={contactedVendors}
					sourcePage={sourcePage}
				/>
				<Button color="primary" size="md" onClick={handleCtaClick}>
					Keep Going
				</Button>
				{!indirectLeadsCount && (
					<InboxLink setCtaTrackProperties={setCtaTrackProperties} />
				)}
			</div>
		</NavigableModal>
	);
};

export default VRMConfirmationModal;
