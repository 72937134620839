import { Caption } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './styles.scss';

export interface RfqLegalCopyProps {
	btnText?:
		| 'Submit'
		| 'Request Quote'
		| 'Schedule Appointment'
		| 'Send'
		| 'Send request';
}

export const RfqLegalCopy = (props: RfqLegalCopyProps) => {
	const { btnText = 'Request Quote' } = props;

	return (
		<Caption size="sm" className={Styles.legalCopy} id="terms-and-conditions">
			{`By clicking '${btnText}', you agree to The Knot creating an account for you, if you don't have one, and to our `}
			<a
				href="https://www.theknotww.com/terms-of-use"
				rel="noopener noreferrer"
				target="_blank"
				className={Styles.link}
			>
				Terms of Use
			</a>
			. See our{' '}
			<a
				href="https://www.theknotww.com/privacy-policy"
				rel="noopener noreferrer"
				target="_blank"
				className={Styles.link}
			>
				Privacy Policy
			</a>{' '}
			to learn what data we collect and how we use it.
		</Caption>
	);
};

export default RfqLegalCopy;
