import * as React from 'react';
const TheKnotSmallLogo = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>TheKnotSmallLogo</title>
		<g id="Mark">
			<path
				id="Vector"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.78789 8.68083L6.47734 0L10.3261 0.305678L9.52156 10.4354C10.0754 10.553 10.6209 10.6062 11.1428 10.5904C12.2482 10.557 13.2135 10.2205 13.9862 9.57707C14.7583 8.93423 15.471 7.87415 15.8753 6.1667L19.6323 7.05647C19.0714 9.42442 17.9823 11.274 16.4566 12.5442C14.9316 13.8139 13.1032 14.3938 11.2593 14.4495C10.1933 14.4817 9.11269 14.3409 8.05394 14.0519C8.08069 14.0923 8.10757 14.1327 8.13452 14.1731C9.21868 15.7979 10.4334 17.3424 11.681 18.4653C12.9652 19.6212 14.0413 20.1124 14.8715 20.1241C16.5316 20.1475 17.5728 19.5182 18.3763 18.4492C18.5739 18.1864 18.7616 17.8894 18.9396 17.5573C19.1017 17.2551 19.254 16.9265 19.3973 16.5723L22.9765 18.0201C22.7881 18.486 22.578 18.942 22.3425 19.3815C22.0838 19.864 21.7925 20.33 21.4626 20.769C19.9661 22.7598 17.7878 24.0263 14.8172 23.9846C12.6013 23.9534 10.6467 22.7288 9.09814 21.3351C8.95958 21.2104 8.82228 21.0825 8.68632 20.9519L8.45088 23.9167L4.60209 23.611L5.15425 16.6589C5.07611 16.5444 4.999 16.4301 4.92295 16.3161C3.74435 14.5498 2.77178 12.7974 2.09608 11.4931C1.75715 10.8389 1.49033 10.2924 1.30705 9.90684C1.21536 9.7139 1.14443 9.56098 1.09568 9.45459C1.07131 9.40143 1.05247 9.3598 1.03934 9.3307C1.03278 9.31609 1.02765 9.30469 1.02396 9.29645L1.01952 9.28646L1.01812 9.28331L1.01763 9.28223C1.01744 9.28176 1.01727 9.28143 2.78212 8.49915L1.01727 9.28143L4.23969 7.23339C4.7106 7.77566 5.23328 8.26009 5.78789 8.68083Z"
				fill="black"
			/>
		</g>
	</svg>
);

export default TheKnotSmallLogo;
