import profileProperties from './profile_properties';

export default `
query PvRecCrossCategory($id: ID!, $categoryCodes: String, $limit: Int, $apiKey: String!, $version: String) {
  pvRecCrossCategory(id: $id, categoryCodes: $categoryCodes, limit: $limit, apiKey: $apiKey, version: $version) {
    recommendations {
      profiles {
        ...ProfileProperties
      }
    }
  }
}
${profileProperties}
`;
