import { GRAPHQL_ERRORS, SUPERAGENT_ERRORS } from './report/constants';

export const isAPIErrorResponse = <T extends unknown>(
	responseData: API.Response<T>,
): responseData is Required<API.ErrorResponse<T>> =>
	typeof (responseData as API.ErrorResponse<T>).errors !== 'undefined';

export const isGraphQLError = (
	name: API.ErrorName,
): name is API.GraphQLErrorName =>
	GRAPHQL_ERRORS.includes(name as API.GraphQLErrorName);

export const isSuperagentError = (
	name: API.ErrorName,
): name is API.SuperAgentErrorName =>
	SUPERAGENT_ERRORS.includes(name as API.SuperAgentErrorName);

// TODO: Parameter should not be any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInvalidResponse = <T>(
	errorPayload: any,
): errorPayload is API.InvalidResponse<T> =>
	(errorPayload as API.InvalidResponse<T>).isInvalid;
